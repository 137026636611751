<template>
  <div duplicated-verification>
    <h2>DUPLICATE ACCOUNT</h2>
    <h3>{{ $t('_.title') }}</h3>
    <p v-t:_.guide />
    <div>
      <template v-if="email">
        <svg-fail class="email-fail" />
        <p v-t:_.message="{account: email}"></p>
      </template>
      <template v-else>
        <div class="provider">
          <svg-google v-if="providerType === 'GOOGLE'" />
          <svg-facebook v-if="providerType === 'FACEBOOK'" />
          <svg-twitch v-if="providerType === 'TWITCH'" />
          <svg-discord v-if="providerType === 'DISCORD'" />
          <svg-steam v-if="providerType === 'STEAM'" />
        </div>
        <p v-t:_.message="{account: providerType}"></p>
      </template>
      <p v-t:_.subMessage />
    </div>
    <PrimaryButton color="red" @click="next">{{ $t('confirm') }}</PrimaryButton>
  </div>
</template>

<script>
import SvgFail from '@shared/graphics/svg-fail.vue';
import PrimaryButton from '@shared/components/common/PrimaryButton.vue';
import SvgGoogle from '@shared/graphics/svg-google.vue';
import SvgFacebook from '@shared/graphics/svg-facebook.vue';
import SvgTwitch from '@shared/graphics/svg-twitch.vue';
import SvgDiscord from '@shared/graphics/svg-discord.vue';
import SvgSteam from '@shared/graphics/svg-steam.vue';
import { i18n } from '@/plugins/i18n';

export default {
  name: 'DuplicatedVerification',
  lexicon: 'account.duplicatedVerification',
  components: { SvgSteam, SvgDiscord, SvgTwitch, SvgFacebook, SvgGoogle, PrimaryButton, SvgFail },
  computed: {
    email() {
      return this.$store.state.signup.duplicatedVerificationInfo?.email;
    },
    providerType() {
      return this.$store.state.signup.duplicatedVerificationInfo?.providerType;
    },
  },
  methods: {
    async next() {
      await this.routeName('Home');
      this.$services.auth.oAuthLogin(true);
    },
  },
  async afterAsyncData({ route, store }) {
    if (!store.state.signup.duplicatedVerificationInfo) {
      const to = `/${i18n.pathByRouteLocale(route.params.locale)}`;
      throw { code: 302, to };
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[duplicated-verification] {
  h3 + p { .-b(@c-base-gray); .mb(24); .pb(20); .tc;
    em { .c(#df3a3a); .underline; .bold; }
  }
  > div { .tc; .mv(50);
    svg.email-fail { .wh(auto, 48); .mb(14);
      .fill-target { fill: #a4a3ae; }
    }
    .provider { .wh(100, 48); .mb(14); .bgc(#ebebf0); .br(28); .flex; .items-center; .justify-center; .mh-c;
      svg { .h(20);
        .fill-target { fill: #a4a3ae }
      }
    }
    p {
      em { .c(#df3a3a); .underline; }
    }
  }
  [primary-button] { .wh(100%, 56); .br(28); .fs(16); }

  @media (@tp-up) {
    > div { .mv(100); }
  }
}

</style>
